<template>
  <div class="exchange-page">
    <HeadNav/>
    <div v-if="exchangeType">
      <div class="info-item">
        <yd-input
          slot="right"
          v-model="mobile"
          required
          regex="mobile"
          :show-success-icon="false"
          :show-error-icon="false"
          placeholder="请输入你的手机号"
        ></yd-input>
      </div>
      <div class="info-item" style="position: relative;">
        <yd-input
          slot="right"
          type="number"
          max="6"
          v-model="sms_code"
          required
          :show-success-icon="false"
          :show-error-icon="false"
          placeholder="请输入短信验证码"
        ></yd-input>
        <div
          class="btn send-btn flex-center"
          v-show="code_bool"
          @click.prevent="getSmsCode"
        >
          发送验证码
        </div>
        <div class="countdown flex-center" v-show="!code_bool">
          {{ count }}s
        </div>
      </div>
    </div>
    <div v-else>
      <div class="info-item">
        <yd-input
          slot="right"
          v-model="card_sn"
          required
          :show-success-icon="false"
          :show-error-icon="false"
          placeholder="请输入兑换卡号"
        ></yd-input>
      </div>
      <div class="info-item">
        <yd-input
          slot="right"
          v-model="card_pass"
          required
          :show-success-icon="false"
          :show-error-icon="false"
          placeholder="请输入兑换密码"
        ></yd-input>
      </div>
    </div>
    <p class="tip-txt" @click="exchangeType = !exchangeType">
      {{ exchangeType ? "卡密兑换" : "手机验证码兑换" }}
    </p>
    <div class="btn-con" @click.stop="verifyCardTicket">
      <MyButton title="查询" :is_disabled="is_disabled"></MyButton>
    </div>

    <!-- S 提示弹窗 -->
    <alert-tip
      :type="type"
      :show-alert="show_tip"
      :alert-text="tip"
      @changeShowAlert="changeShow"
    ></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import MyButton from "@/components/button/button";
import AlertTip from "@/components/common/AlertTip";
import HeadNav from "@/components/common/HeadNav";
import { toolTip } from "@/mixins";
import Validator from "@/utils/validator";
import { debounce } from "@/utils/debounce";
import { verifyCardTicket, verifyMobileTicket } from "@/services/my";
import { sendCardTicket } from "@/services/user";
import { setStore, removeStore } from "@/utils/common";

const COMPUTED_TIME = 60;
export default {
  name: "exchange",
  components: {
    MyButton,
    AlertTip,
    HeadNav
  },
  mixins: [toolTip],
  data() {
    return {
      card_sn: "",
      card_pass: "",
      exchangeType: false, //false为卡密兑换、true为验证码兑换
      mobile: "",
      sms_code: "",
      code_bool: true,
      count: "", // 倒计时
      timer: null, // 计时器
      is_disabled: false
    };
  },
  methods: {
    //获取验证码
    getSmsCode: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
      const res = await sendCardTicket(mobile);
      if (res.code === 0) {
        this.show_layer("success", "验证码已发送，请注意查收");
        this.count = COMPUTED_TIME;
        this.code_bool = false;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }else{
         this.$dialog.toast({
            mes: res.msg
          });
      }
    }, 300),

    /**
     * 卡券验证
     * @returns {Promise<void>}
     */
    verifyCardTicket: debounce( async function () {
      try {
        let params = {};
        this.exchangeType
          ? (params = { card_sn: this.mobile, code: this.sms_code })
          : (params = { card_sn: this.card_sn, card_pass: this.card_pass });
        let res = null;
        if (this.exchangeType) {
          if (!params.card_sn) {
            this.$dialog.toast({
              mes: "请输入手机号"
            });
            return;
          }
          if (!params.code) {
            this.$dialog.toast({
              mes: "请输入验证码"
            });
            return;
          }
          res = await verifyMobileTicket(params);
        } else {
          if (!params.card_sn) {
            this.$dialog.toast({
              mes: "请输入兑换卡号"
            });
            return;
          }
          if (!params.card_pass) {
            this.$dialog.toast({
              mes: "请输入兑换密码"
            });
            return;
          }
          res = await verifyCardTicket(params);
        }
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.$router.push({
            name: "exchange_result",
            params: {
              type: data.card_info.card_type, //1储值卡，2兑换卡
              amount: data.card_info.card_amount, //1储值卡，2兑换卡
              business_type: data.card_info.business_type, //现金、积分显示
              id: data.card_info.card_id,
              card_ticket_id: data.card_ticket_id,
              card_sn: data.card_sn,
              card_pass: this.card_pass
            }
          });
          removeStore("card_ticket_id");
          setStore("card_ticket_id", data.card_ticket_id);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: "error"
          });
        }
      } catch (e) {}
    }, 300)
  }
};
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.exchange-page {
  .info-item {
    .sc(0.32rem, #333);
    background: @color-white;
    .wh(100%, 1rem);
    padding: 0 0.24rem;
    margin-top: 0.2rem;

    input::placeholder {
      .sc(0.32rem, @color-light-grey);
    }

    .send-btn,
    .countdown {
      .ct;
      right: 0.2rem;
      .wh(1.68rem, 0.52rem);
      .sc(@fontsize-small, #fff);
      line-height: 0.52rem;
      text-align: center;
      white-space: nowrap;
      border-radius: 0.26rem;
      z-index: 4;
    }

    .send-btn {
      background: var(--main-color);
      .touch-active;
    }

    .countdown {
      background: @color-light-grey;
    }
  }

  .tip-txt {
    margin: 0.44rem 0 0 0.24rem;
    color: var(--main-color);
    font-size: 0.28rem;
  }

  .btn-con {
    margin-top: 1rem;
  }
}
</style>
