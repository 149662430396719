var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exchange-page" },
    [
      _c("HeadNav"),
      _vm.exchangeType
        ? _c("div", [
            _c(
              "div",
              { staticClass: "info-item" },
              [
                _c("yd-input", {
                  attrs: {
                    slot: "right",
                    required: "",
                    regex: "mobile",
                    "show-success-icon": false,
                    "show-error-icon": false,
                    placeholder: "请输入你的手机号",
                  },
                  slot: "right",
                  model: {
                    value: _vm.mobile,
                    callback: function ($$v) {
                      _vm.mobile = $$v
                    },
                    expression: "mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "info-item",
                staticStyle: { position: "relative" },
              },
              [
                _c("yd-input", {
                  attrs: {
                    slot: "right",
                    type: "number",
                    max: "6",
                    required: "",
                    "show-success-icon": false,
                    "show-error-icon": false,
                    placeholder: "请输入短信验证码",
                  },
                  slot: "right",
                  model: {
                    value: _vm.sms_code,
                    callback: function ($$v) {
                      _vm.sms_code = $$v
                    },
                    expression: "sms_code",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.code_bool,
                        expression: "code_bool",
                      },
                    ],
                    staticClass: "btn send-btn flex-center",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getSmsCode.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        发送验证码\n      ")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.code_bool,
                        expression: "!code_bool",
                      },
                    ],
                    staticClass: "countdown flex-center",
                  },
                  [_vm._v("\n        " + _vm._s(_vm.count) + "s\n      ")]
                ),
              ],
              1
            ),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "info-item" },
              [
                _c("yd-input", {
                  attrs: {
                    slot: "right",
                    required: "",
                    "show-success-icon": false,
                    "show-error-icon": false,
                    placeholder: "请输入兑换卡号",
                  },
                  slot: "right",
                  model: {
                    value: _vm.card_sn,
                    callback: function ($$v) {
                      _vm.card_sn = $$v
                    },
                    expression: "card_sn",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "info-item" },
              [
                _c("yd-input", {
                  attrs: {
                    slot: "right",
                    required: "",
                    "show-success-icon": false,
                    "show-error-icon": false,
                    placeholder: "请输入兑换密码",
                  },
                  slot: "right",
                  model: {
                    value: _vm.card_pass,
                    callback: function ($$v) {
                      _vm.card_pass = $$v
                    },
                    expression: "card_pass",
                  },
                }),
              ],
              1
            ),
          ]),
      _c(
        "p",
        {
          staticClass: "tip-txt",
          on: {
            click: function ($event) {
              _vm.exchangeType = !_vm.exchangeType
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.exchangeType ? "卡密兑换" : "手机验证码兑换") +
              "\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "btn-con",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.verifyCardTicket.apply(null, arguments)
            },
          },
        },
        [
          _c("MyButton", {
            attrs: { title: "查询", is_disabled: _vm.is_disabled },
          }),
        ],
        1
      ),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }